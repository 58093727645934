<template>

    <section class="login">
      <div class="container-scroller">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div class="content-wrapper d-flex align-items-center auth">
            <div class="row w-100 flex-grow">
              <div class="col-xl-4 col-lg-6 mx-auto">
                <div class="auth-form-light text-left p-5 same_modal">
                  <div class="brand-logo text-center">
                    <img src="@/assets/images/believeLogoMini.png" style="width: 100px;">
                  </div>
                  <h4 class="text-center">Setup New Password</h4>
                  <h6 class="font-weight-light text-center">Already have reset your password ?
                  <router-link to="login" class="link-primary fw-bolder">Sign in here</router-link></h6>
                  <form class="pt-3" @submit.prevent="onSubmit">
                    <div class="form-group" :class="{ error: v$.new_password.$errors.length  }">
                      <input  v-if="showPassword" type="text" 
                      class="form-control form-control-lg" id="exampleInputEmail1"
                        placeholder="" v-model="v$.new_password.$model" autocomplete="off" required>

                        <input  v-else type="password" 
                      class="form-control form-control-lg" id="exampleInputEmail1"
                        placeholder="" v-model="v$.new_password.$model" autocomplete="off" required>


                        <span
                        class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                        data-kt-password-meter-control="visibility"
                      >
                        
                          <span class="icon is-small is-right" @click="toggleShow">
                            <i
                              class="fa"
                              :class="{
                                'fa-eye-slash': showPassword,
                                'fa-eye': !showPassword,
                              }"
                            ></i>
                          </span>
                    
                      </span>
                      
                      <div class="input-errors mt-3" v-for="(error, index) of v$.new_password.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>


                    <div class="form-group" :class="{ error: v$.confirm_password.$errors.length  }">
                      <input  v-if="showPassword" type="text" 
                      class="form-control form-control-lg" id="exampleInputEmail1"
                        placeholder="" v-model="v$.confirm_password.$model" autocomplete="off" required>

                        <input  v-else type="password" 
                      class="form-control form-control-lg" id="exampleInputEmail1"
                        placeholder="" v-model="v$.confirm_password.$model" autocomplete="off" required>
                      

                      <div class="input-errors" v-for="(error, index) of v$.confirm_password.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>

                   
                    <!-- <router-link to="/forgetpassword" class="link-primary fs-6 fw-bolder">
                        <i class="fa fa-lock"></i> Forgot your password?
                      </router-link> -->
                    <div class="mt-3">
                      <button type="button" v-on:click="newPasswordSubmitted"
                        class="btn btn-block same_btn btn-lg font-weight-medium auth-form-btn believe-btn"
                        >Submit</button>
                    </div>
                    <!-- <div class="my-2 d-flex justify-content-between align-items-center">
                      <div class="form-check">
                        <label class="form-check-label text-muted">
                          <input type="checkbox" class="form-check-input">
                          Keep me signed in
                          <i class="input-helper"></i>
                        </label>
                      </div>
                      <a href="javascript:void(0);" class="auth-link text-black">Forgot password?</a>
                    </div> -->
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- content-wrapper ends -->
        </div>
        <!-- page-body-wrapper ends -->
      </div>
      <vue-snotify></vue-snotify>
    </section>
  
  </template>
  
  
  <script>
  import api from "../../config/api.js";
  import useVuelidate from "@vuelidate/core";
  import { required, minLength,sameAs } from "@vuelidate/validators";
  export default {
    name: "ConfirmPassword",
  
    data() {
      return {
        new_password: null,
        confirm_password: null,
        email: "",
        token: "",
        showPassword: false,
      };
    },
    setup() {
      return { v$: useVuelidate() };
    },
    validations() {
      return {
        new_password: { required, min: minLength(6) },
        confirm_password: {
            required,
            sameAsPassword: sameAs(this.new_password),
          },
      };
    },
  
    computed: {
      user_email() {
        return this.$store.state.user.name;
      },
      user_token() {
        return this.$store.state.user.name;
      },
    },
  
    methods: {
      toggleShow() {
        this.showPassword = !this.showPassword;
      },
  
      async newPasswordSubmitted() {
        this.email = this.$store.state.email;
        this.token = this.$store.state.token;
  
        try {
          let result = await api.post(`/admin/confirm-password`, {
            new_password: this.new_password,
            confirm_password: this.confirm_password,
            email: this.email,
            reset_token: this.token,
          });
  
          console.log(result);
          localStorage.setItem("loginkey", result.data.message);
        //   this.$toast.success(result.data.message);
          this.$swal('Success!', result.data.message , 'success')
          this.$router.push({ name: "Login" });
        } catch (error) {
          this.error = error.response.data.message;
        //   this.$toast.error(this.error);
            this.$swal('Error!', this.error , 'error')
        }
      },
  
      // let togglePassword = document.querySelector('#togglePassword');
      //   let password = document.querySelector('#id_password');
  
      //   togglePassword.addEventListener('click', function (e) {
      //     // toggle the type attribute
      //     const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
      //     password.setAttribute('type', type);
      //     // toggle the eye slash icon
      //     this.classList.toggle('fa-eye-slash');
      // });
    },
  };
  </script>
  
  <!-- <style scoped>
  button {
    border: none !important;
    background: transparent !important;
  }
  </style> -->
  